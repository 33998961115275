import React, { useEffect, useState } from 'react';
import type { FC, MouseEvent } from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import Button from '@atlaskit/button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { G300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useFlags } from '@atlaskit/flag';
import SuccessIcon from '@atlaskit/icon/core/success';
import FeedbackCollector from '@atlaskit/feedback-collector';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import { Box, Inline, xcss } from '@atlaskit/primitives';

import { useAIEventsInstrumentation } from '@atlassian/ai-analytics';

import { ErrorDisplay } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';

import { ThumbsIcon } from '../assets/ThumbIcon';
import { useCurrentUserContributor } from '../hooks/useCurrentUserContributor';

import type { SummaryAnalyticsPropertiesType } from './summarySharedTypes';
import { useFeedbackCollectorProperties } from './FeedbackCollectorPropertiesProvider';

const LINK_TO_PRIVACY_POLICY = 'https://www.atlassian.com/legal/privacy-policy';
const LINK_TO_RESEARCH_GROUP = 'https://www.atlassian.com/research-group';

const i18n = defineMessages({
	flagTitle: {
		id: 'quick-summary.feedback.flag.title',
		defaultMessage: 'Thanks!',
		description: 'Feedback Flag Title',
	},
	flagDescription: {
		id: 'quick-summary.feedback.flag.description',
		defaultMessage: 'We appreciate you taking the time to send this feedback.',
		description: 'Feedback Flag Description',
	},
	likeTooltip: {
		id: 'quick-summary.like.tooltip',
		defaultMessage: 'Good response',
		description: 'Like Tooltip text',
	},
	dislikeTooltip: {
		id: 'quick-summary.dislike.tooltip',
		defaultMessage: 'Bad response',
		description: 'Dislike Tooltip text',
	},
	feedbackTitle: {
		id: 'quick-summary.feedback.title',
		defaultMessage: 'Help us improve Atlassian Intelligence',
		description: 'Label text for the summary section of the report feedback collector',
	},
	feedbackSummaryTitle: {
		id: 'quick-summary.feedback.summary.title',
		defaultMessage: 'Tell us about your experience',
		description: 'Title text for the summary section of the report feedback collector',
	},
	feedbackSummaryPlaceholder: {
		id: 'quick-summary.feedback.summary.placeholder',
		defaultMessage: 'What did Atlassian Intelligence do well? What could be improved?',
		description: 'Description text for the summary section of the report feedback collector',
	},
	canContactText: {
		id: 'quick-summary.feedback.can-contact.label',
		defaultMessage: 'Atlassian can contact me about this feedback. See our {privacyPolicyLink}.',
		description:
			"Text next to first checkbox. {privacyPolicyLink} will be substituted with a link whose text reads 'privacy policy'",
	},
	privacyPolicyLink: {
		id: 'quick-summary.feedback.privacy-policy.link',
		defaultMessage: 'privacy policy',
		description: 'The text of the link that will direct users to the privacy policy site.',
	},
	improveAtlassianText: {
		id: 'quick-summary.feedback.improve-atlassian',
		defaultMessage:
			'I’d like to help improve Atlassian products by joining the {researchGroupLink}.',
		description:
			"Text next to second checkbox. {researchGroupLink} will be substituted with a message which reads as 'Atlassian Research Group'",
	},
	researchGroupLink: {
		id: 'quick-summary.feedback.research-group.link',
		defaultMessage: 'Atlassian Research Group',
		description: 'The text of the link that will direct users to the research group site.',
	},
});

type LinkType = {
	link: string;
};

const PrivacyPolicyLink = ({ link }: LinkType) => (
	<a target="_blank" href={link}>
		<FormattedMessage {...i18n.privacyPolicyLink} />
	</a>
);

const ResearchGroupLink = ({ link }: LinkType) => (
	<a target="_blank" href={link}>
		<FormattedMessage {...i18n.researchGroupLink} />
	</a>
);

const CanContactLabel = () => {
	return (
		<FormattedMessage
			{...i18n.canContactText}
			values={{
				privacyPolicyLink: <PrivacyPolicyLink link={LINK_TO_PRIVACY_POLICY} />,
			}}
		/>
	);
};

const EnrolLabel = () => {
	return (
		<FormattedMessage
			{...i18n.improveAtlassianText}
			values={{
				researchGroupLink: <ResearchGroupLink link={LINK_TO_RESEARCH_GROUP} />,
			}}
		/>
	);
};

const DislikeIconWrapperXCSS = xcss({
	transform: 'rotate(180deg)',
});

const ButtonWrapperXCSS = xcss({
	height: '24px',
	width: '24px',
});

type Sentiment = 'good' | 'bad' | '';

export type SmartsFeedbackComponentProps = {
	analyticsProperties: SummaryAnalyticsPropertiesType;
	contentId: string;
	summaryId?: string;
	summaryLocale?: string;
};

export const SmartsFeedbackComponent: FC<SmartsFeedbackComponentProps> = ({
	analyticsProperties,
	contentId,
	summaryId,
	summaryLocale,
}) => {
	const { showFlag } = useFlags();
	const { formatMessage } = useIntl();
	const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const feedbackCollectorProperties = useFeedbackCollectorProperties();
	const { isCurrentUserContributor, contentContributorsError } =
		useCurrentUserContributor(contentId);
	const [sentiment, setSentiment] = useState<Sentiment>('');
	const [hasGoodFeedbackBeenSubmitted, setHasGoodFeedbackBeenSubmitted] = useState(false);
	const [hasBadFeedbackBeenSubmitted, setHasBadFeedbackBeenSubmitted] = useState(false);
	const { cloudId } = useSessionData();
	const { trackAIFeedbackSubmit } = useAIEventsInstrumentation();

	const feedbackContextString = `contentId: ${contentId} sentiment: ${sentiment} cloudId: ${cloudId} isCurrentUserContributor: ${isCurrentUserContributor}${
		feedbackCollectorProperties.feedbackContext.length > 0
			? ` ${feedbackCollectorProperties.feedbackContext}`
			: ''
	}`;

	useEffect(() => {
		if (isFeedbackOpen) {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: `${analyticsProperties.source}FeedbackCollectorDialog`,
					attributes: {
						contentId,
						summaryId,
						summaryLocale,
						sentiment,
						...analyticsProperties.additionalAnalyticsAttributes,
					},
				},
			}).fire();
		}
	}, [
		createAnalyticsEvent,
		isFeedbackOpen,
		contentId,
		summaryId,
		summaryLocale,
		sentiment,
		analyticsProperties,
	]);

	const showFeedbackFlag = () => {
		showFlag({
			title: formatMessage(i18n.flagTitle),
			description: formatMessage(i18n.flagDescription),
			icon: <SuccessIcon color={token('color.icon.success', G300)} label="success" />,
			isAutoDismiss: true,
		});
	};

	const onLikeClicked = () => {
		setIsFeedbackOpen(true);
		setSentiment('good');

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}LikeButton`,
				attributes: {
					contentId,
					summaryId,
					summaryLocale,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();

		trackAIFeedbackSubmit('up');
	};

	const onDislikeClicked = () => {
		setIsFeedbackOpen(true);
		setSentiment('bad');
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}DislikeButton`,
				attributes: {
					contentId,
					summaryId,
					summaryLocale,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
		trackAIFeedbackSubmit('down');
	};

	const onFeedbackSubmit = () => {
		showFeedbackFlag();
		setIsFeedbackOpen(false);

		if (sentiment === 'good') {
			setHasGoodFeedbackBeenSubmitted(true);
			setHasBadFeedbackBeenSubmitted(false);
		} else {
			setHasBadFeedbackBeenSubmitted(true);
			setHasGoodFeedbackBeenSubmitted(false);
		}

		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				source: analyticsProperties.source,
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: `${analyticsProperties.source}SubmitFeedbackButton`,
				attributes: {
					contentId,
					summaryId,
					summaryLocale,
					sentiment,
					...analyticsProperties.additionalAnalyticsAttributes,
				},
			},
		}).fire();
	};

	return (
		<Box onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation()}>
			<Inline alignBlock="center" space="space.050">
				<Tooltip hideTooltipOnMouseDown content={formatMessage(i18n.likeTooltip)} position="top">
					<Box xcss={ButtonWrapperXCSS}>
						<Button
							appearance="subtle"
							aria-label={formatMessage(i18n.likeTooltip)}
							spacing="none"
							iconBefore={<ThumbsIcon isSelected={hasGoodFeedbackBeenSubmitted} />}
							onClick={onLikeClicked}
							testId="feedback-like-button"
							isDisabled={hasGoodFeedbackBeenSubmitted}
						/>
					</Box>
				</Tooltip>
				<Tooltip hideTooltipOnMouseDown content={formatMessage(i18n.dislikeTooltip)} position="top">
					<Box xcss={ButtonWrapperXCSS}>
						<Button
							appearance="subtle"
							aria-label={formatMessage(i18n.dislikeTooltip)}
							spacing="none"
							iconBefore={
								<Box xcss={DislikeIconWrapperXCSS}>
									<ThumbsIcon isSelected={hasBadFeedbackBeenSubmitted} />
								</Box>
							}
							onClick={onDislikeClicked}
							testId="feedback-dislike-button"
							isDisabled={hasBadFeedbackBeenSubmitted}
						/>
					</Box>
				</Tooltip>
				{isFeedbackOpen && (
					<FeedbackCollector
						locale={feedbackCollectorProperties.locale}
						onSubmit={onFeedbackSubmit}
						onClose={() => setIsFeedbackOpen(false)}
						atlassianAccountId={feedbackCollectorProperties.userId}
						name={feedbackCollectorProperties.displayName}
						entrypointId={feedbackCollectorProperties.entrypointId}
						summaryPlaceholder={formatMessage(i18n.feedbackSummaryPlaceholder)}
						additionalFields={[
							{
								id: feedbackCollectorProperties.feedbackContextId,
								value: feedbackContextString,
							},
						]}
						feedbackTitle={formatMessage(i18n.feedbackTitle)}
						showTypeField={false}
						customTextAreaLabel={formatMessage(i18n.feedbackSummaryTitle)}
						canBeContactedLabel={<CanContactLabel />}
						enrolInResearchLabel={<EnrolLabel />}
					/>
				)}
			</Inline>
			{contentContributorsError && <ErrorDisplay error={contentContributorsError} />}
		</Box>
	);
};
